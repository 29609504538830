.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #28659e, #08121b);
  overflow: hidden; /* Prevent bubbles from extending outside the viewport */
  position: relative; /* Allows absolute-positioned bubbles to move */
}

.App-header h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.bubble-text {
  font-size: 3.5rem;
  font-family: 'OpenSans-Regular', sans-serif;
  z-index: 2;
  background: radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.4), rgba(102, 178, 255, 0.2));
  -webkit-background-clip: text; /* Clipping the background to the text */
  color: transparent; 
  -webkit-text-stroke: 0.5px #08121b;
}
.bubble-line {
  display: inline-flex;
  justify-content: center;
}

.bubble-letter {
  display: inline-block; /* Makes sure each letter is treated individually */
  background: radial-gradient(circle at 20% 20%, rgba(255, 255, 255, 0.8), rgba(102, 178, 255, 0.1));
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  margin: 0 2px; /* Add some spacing between letters */
  font-size: 4rem;
  font-family: 'OpenSans-Regular', sans-serif;
}

.bubbles {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1; /* Keep bubbles behind the text */
}

.bubble {
  position: absolute;
  bottom: 0px; /* Start below the screen */
  background: radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.4), rgba(102, 178, 255, 0.2));
  border-radius: 50%;
 /* border: 2px solid rgba(102, 178, 255, 0.0); /* Visible border */
  animation: moveUp 8s linear infinite;
  opacity: 0.6;
}

/* Customize individual bubbles */
.bubble:nth-child(1) {
  left: 20vw;
  width: 6vw;
  height: 6vw;
  animation-duration: 8s;
}

.bubble:nth-child(2) {
  left: 37vw;
  width: 9vw;
  height: 9vw;
  animation-duration: 6s;
}

.bubble:nth-child(3) {
  left: 58vw;
  width: 5vw;
  height: 5vw;
  animation-duration: 10s;
}

.bubble:nth-child(4) {
  left: 80vw;
  width: 10vw;
  height: 10vw;
  animation-duration: 7s;
}

.bubble:nth-child(5) {
  left: -5vw;
  width: 10vw;
  height: 10vw;
  animation-duration: 7s;
}

.bubble:nth-child(6) {
  left: 96vw;
  width: 8vw;
  height: 8vw;
  animation-duration: 7.5s;
}

/* Define the bubble animation to move them upward */
@keyframes moveUp {
  0% {
    transform: translateY(100vh) scale(0.5);
    opacity: 0.6;
  }
  100% {
    transform: translateY(-100vh) scale(1.2);
    opacity: 0; /* Fade out as bubbles go up */
  }
}

@media (max-width: 600px) {
  .bubble-letter {
    margin: 0 1px;
    font-size: 2rem;
  }
}

@media (max-width: 300px) {
  .bubble-text {
    display: flex;
    flex-direction: column;
    text-align: center;  
  }
}